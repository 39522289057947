import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import Card from "../components/Card"
import Hero from "../components/Hero"
import Container from "../components/Container"

import banner from "../images/404-banner.jpg"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Container>
      <Hero headline="404: Not Found" image={banner} />
      <Card>
        <div>
          <h2>You just hit a route that doesn&#39;t exist...</h2>
          <Link to="/">Click here for the Homepage</Link>
        </div>
      </Card>
    </Container>
  </Layout>
)

export default NotFoundPage
